/*
  This contains the environment variables needed for the application
  in the staging environment
*/

export const environment = {
  ambassador: true,
  baseURL: "https://vms-stg.best-gcp-npd.mlbinfra.net",
  superAdminRole: "SUPER_ADMIN",
  REACT_APP_OKTA_REDIRECT_URI: "https://admin.etastg.mlb.com",
  REACT_APP_OKTA_REDIRECT_URI_VOL: "https://etastg.mlb.com",
  REACT_APP_OKTA_POST_LOGOUT_REDIRECT_URI: "https://admin.etastg.mlb.com",
  REACT_APP_OKTA_POST_LOGOUT_REDIRECT_URI_VOL: "https://etastg.mlb.com",
  REACT_APP_OKTA_BASE_URL: "https://mlbtest.okta.com",
  REACT_APP_OKTA_CLIENT_ID: "0oa94hkpha2XiQCQP1t7",
  REACT_APP_OKTA_ISSUER: "https://mlbtest.okta.com/oauth2/ausa3295vsxer28Br1t7",
  REACT_APP_OKTA_BASE_URL_VOL: "https://qa-ids.mlb.com",
  REACT_APP_OKTA_CLIENT_ID_VOL: "0oa1k6srkm04rhdUV0h8",
  REACT_APP_OKTA_ISSUER_VOL: "https://qa-ids.mlb.com/oauth2/ausg0y0s07iKGqVKv0h7",
};
