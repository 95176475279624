/*******************************************************
  App Entrypoint
*******************************************************/

import React from "react";
import ReactDom from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "draft-js/dist/Draft.css";
import "react-table/react-table.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-virtualized/styles.css";
import "./stylesheets/less/main.less";
import "mobx-react-lite/batchingForReactDom";
import App from "./App";
import WrapperProvider from "./contexts/WrapperProvider";
//import { init as initApm } from "elastic-apm-js-base";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { datadogRum } from "@datadog/browser-rum";

import {
  faBars,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faTrashAlt,
  faCaretDown,
  faCalendarAlt,
  faFileDownload,
  faKey,
  faEnvelope,
  faPencilAlt,
  faEdit,
  faSearch,
  faPlusCircle,
  faMinusCircle,
  faCheck,
  faClock,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faListUl,
  faListOl,
  faQuoteRight,
  faPalette,
  faFont,
  faTextHeight,
  faLink,
  faCalendarPlus,
  faLock,
  faArrowRight,
  faDownload,
  faUpload,
  faAddressCard,
  faAngleDown,
  faAngleUp,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faFilter,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  fab,
  faBars,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faTrashAlt,
  faCaretDown,
  faCalendarAlt,
  faFileDownload,
  faKey,
  faEnvelope,
  faPencilAlt,
  faEdit,
  faSearch,
  faPlusCircle,
  faMinusCircle,
  faCheck,
  faClock,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faListUl,
  faListOl,
  faQuoteRight,
  faPalette,
  faFont,
  faTextHeight,
  faLink,
  faCalendarPlus,
  faLock,
  faArrowRight,
  faDownload,
  faUpload,
  faAddressCard,
  faAngleDown,
  faAngleUp,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faFilter,
  faSyncAlt
);

/** Elastic APM incantation */
if (process.env.APP_ENV !== "local") {
  /*initApm({
    serviceName: `best-vms-ui-${process.env.APP_ENV}`,
    serverUrl: "https://apm-server.systems.mlbinfra.com:8201",
    serviceVersion: ""
  });*/
}

// initialize DataDog APM for non-local
if (process.env.APP_ENV !== "local") {
  datadogRum.init({
    applicationId: process.env.DATADOG_APP_ID,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: "us5.datadoghq.com",
    service: "BEST-ETA",
    env: process.env.APP_ENV,
    sampleRate: 100,
    premiumSampleRate: 1,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input"
  });

  datadogRum.startSessionReplayRecording();
}

ReactDom.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
  document.getElementById("root")
);
